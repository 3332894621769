import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

const Header = ({isHeaderFixed}) => {
    return (
        <header className={`${isHeaderFixed ? 'fix_style fixed' : ''}`}> 
            <div className="container">
                <Navbar expand="lg" fixed="top">
                    <Navbar.Brand as={Link} to="/">
                        <img src="https://apnaphone.pk/img/logo.svg" alt="ApnaPhone Logo"/>
                    </Navbar.Brand>
                    <Nav className="ml-auto d-lg-none d-none d-sm-block toggle-nav">
                        <Nav.Item>
                            <Link to="/instant-cash" className="nav-link dark_btn">Get Instant Cash</Link>
                        </Nav.Item>
                    </Nav>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon">
                            <div className="toggle-wrap">
                                <span className="toggle-bar"></span>
                            </div>
                        </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="main-nav">
                            <Nav.Item>
                                <Link to="/" className="nav-link">Home</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/instant-cash/apple" className="nav-link">Sell iPhone</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/instant-cash/samsung" className="nav-link">Sell Samsung</Link>
                            </Nav.Item>
                            <Nav.Item className='d-sm-none'>
                                <Link to="/instant-cash" className="nav-link dark_btn">Get Instant Cash</Link>
                            </Nav.Item>
                        </Nav>
                        <Nav className="ml-auto d-none d-lg-block">
                            <Nav.Item>
                                <Link to="/instant-cash" className="nav-link dark_btn">Get Instant Cash</Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
    );
}
 
export default Header;