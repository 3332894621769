import { Link, useNavigate } from 'react-router-dom';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useContext } from 'react';
/*
steps
1. Brand
2. Model
3. Variant
4. Cost
5. Provider
6. Condition
7. Issues
8. Form
*/
const Home = () => {
    document.title = "ApnaPhone";
    
    let navigate = useNavigate();

    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12"  data-aos="fade-right" data-aos-duration="1500">
                            <div className="banner_text">
                                <h1>Sell your old phone<br />&amp; get Instant Cash</h1>
                                <p>ApnaPhone - Discover a hassle-free way to sell your old Apple and Samsung phones and receive instant cash for your valuable devices.</p>
                            </div>
                            <Link to="/instant-cash" tabIndex="0" className="instant-cash-button" data-aos="fade-in" data-aos-duration="1500">Get Instant Cash</Link>
                            <div>
                                <a href="https://play.google.com/store/apps/details?id=com.apnaphone.app" className="banner-download" data-aos="fade-in" data-aos-duration="1500"><img src="/images/google-play-button.svg" alt="Download button"/></a>
                            </div>
                        </div>

                        <div className="col-lg-1"></div>
                        <div className="col-lg-4 col-md-12"  data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_image">
                                <img className="moving_animation" src="/images/banner-image.png" alt="Phones"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#f6f4fe" />
                    </svg>
                </div>
            </section>

            <div id="about" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am about_app_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src="/images/about-money.svg" alt="Cash"/>
                                </div>
                                <div className="screen_img zindex-2">
                                    <img className="moving_animation" src="/images/about-phone.jpg" alt="Phone"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>About</span> ApnaPhone</h2>
                                    <p>ApnaPhone provides a hassle-free way to sell your old Apple and Samsung phones and receive instant cash for your valuable devices.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>

                                <Link to="/instant-cash" tabIndex="0" className="instant-cash-button" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">Get Instant Cash</Link>

                                <div>
                                    <a href="https://play.google.com/store/apps/details?id=com.apnaphone.app" className="about-download" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="500"><img src="/images/google-play-button.svg" alt="Download button"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div id="why-choose" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am modern_ui_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>Models </span> We Accept</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <ul className="design_block">
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Apple iPhones</h4>
                                        <p>Major iPhones includes iPhone 14, iPhone 13, iPhone 12, iPhone 11, iPhone SE.</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Samsung mobiles</h4>
                                        <p>Major samsung mobiles including Galaxy A series, Galaxy S series, Galaxy F series.</p>
                                    </li>
                                </ul>
                                
                                <Link to="/instant-cash" tabIndex="0" className="instant-cash-button" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">Get Instant Cash</Link>
                            </div>
                        </div>

                        <div className="col-lg-5 mt-3 mt-lg-0 d-flex d-lg-block">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src="https://apnaphone.pk/images/brands/apple.svg" alt="Apple"/>
                                </div>
                                <div className="screen_img moving_animation zindex-2">
                                    <img src="https://apnaphone.pk/images/brands/samsung.svg" alt="Samsung"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="faqs" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2>Frequently Asked <span>Questions</span></h2>
                        <p>Need help getting started with ApnaPhone? Our FAQs have got you covered<br/> by providing clear and concise answers to all your questions.</p>
                    </div>

                    <div className="faq_panel">
                        <Accordion defaultActiveKey="0">
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header">
                                    <CustomAccordianToggle eventKey="0">In which states ApnaPhone available?</CustomAccordianToggle>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <div className="card-body">
                                        <p>ApnaPhone is available currently in California, Texas and Florida.</p>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header">
                                    <CustomAccordianToggle eventKey="1">How can I sell my phone?</CustomAccordianToggle>
                                </div>
                                <Accordion.Collapse eventKey="1">
                                    <div className="card-body">
                                        <p>You can submit Instant Cash request by entering details about mobile phones, our representative will get back to you as soon as possible.</p>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    );
}

function CustomAccordianToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <h2 className="mb-0" onClick={decoratedOnClick}>
            <button type="button" className={`btn btn-link ${isCurrentEventKey ? 'active' : ''}`} tabIndex="-1">
                {children}
                {isCurrentEventKey ? (
                    <img src="/images/icon-minus.svg" alt="View less" className="icon_faq minus"/>
                ) : (
                    <img src="/images/icon-plus.svg" alt="View more" className="icon_faq plus"/>
                )}
            </button>
        </h2>
    );
}
 
export default Home;