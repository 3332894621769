import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from "./Header";
import Footer from "./Footer";

import Home from "./Home";
import InstantCashBrand from "./InstantCashBrand";
import InstantCashBrandWhite from "./InstantCashBrandWhite";
import InstantCashBrandGrey from "./InstantCashBrandGrey";
import InstantCashBrandGrey2 from "./InstantCashBrandGrey2";

const App = () => {
    useEffect(() => {
        AOS.init({
            once: true
        });
    }, [])

    return ( 
        <>
            <Routes>
                <Route element={<AppLayout />} >
                    <Route path="/" element={<Home />} />
                    <Route path="/instant-cash" element={<InstantCashBrand />} />
                    <Route path="/instant-cash-white" element={<InstantCashBrandWhite />} />
                    <Route path="/instant-cash-grey" element={<InstantCashBrandGrey />} />
                    <Route path="/instant-cash-grey-2" element={<InstantCashBrandGrey2 />} />
                    <Route path="/instant-cash/:quickBrand" element={<InstantCashBrand />} />
                </Route>
            </Routes>
        </>
    );
}

const AppLayout = () => {
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 250) {
                setIsHeaderFixed(true);
            } else {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`page_wrapper`}>
            <Header isHeaderFixed={isHeaderFixed} />
            
            <Outlet />

            <Footer />
        </div>
    )
};
 
export default App;