import { Link, useNavigate, useParams } from 'react-router-dom';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useContext, useEffect, useState, useRef } from 'react';
import useFetch from '../useFetch';

/*
steps
1. Brand
2. Model
3. Variant
4. Cost
5. Provider
6. Condition
7. Issues
8. Form
*/

const InstantCashBrandWhite = () => {
    document.title = "Get Instant Cash - ApnaPhone";
    const { quickBrand } = useParams();

    const mainViewRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)
    const [instantCash, setInstantCash] = useState(null)
    const [validationErrors, setValidationErrors] = useState([]);
    const [conditionError, setConditionError] = useState(null)
    const [issueError, setIssueError] = useState(null)
    const [accessoryError, setAccessoryError] = useState(null)

    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');

    const [step, setStep] = useState(1)
    const [brand, setBrand] = useState(null)
    const [model, setModel] = useState(null)
    const [variant, setVariant] = useState(null)
    const [provider, setProvider] = useState('')
    const [condition1, setCondition1] = useState('')
    const [condition2, setCondition2] = useState('')
    const [condition3, setCondition3] = useState('')
    const [condition4, setCondition4] = useState('')
    const [condition5, setCondition5] = useState('')
    const [condition6, setCondition6] = useState('')
    const [issues, setIssues] = useState(null)
    const [hasBox, setHasBox] = useState(null)
    const [hasCharger, setHasCharger] = useState(null)
    
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [variants, setVariants] = useState([]);

    const { response, isLoading: isLoadingBrand, error: fetchError } = useFetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash');

    useEffect(() => {
        if (quickBrand)
        {
            if (response?.brands?.length > 0)
            {
                var quickBrandObj = response?.brands.find(item => item.name.toLowerCase() == quickBrand)
                if (quickBrandObj)
                {
                    setBrand(quickBrandObj)
                    setStep(2)
                    setModels(quickBrandObj.mobiles ?? [])
                }
            }
        }
        else
        {
            setBrand(null)
            setStep(1)
            setModels([])
        }
    }, [quickBrand])

    useEffect(() => {
        setBrands(response?.brands ?? []);
        if (response?.brands?.length > 0 && quickBrand)
        {
            var quickBrandObj = response?.brands.find(item => item.name.toLowerCase() == quickBrand)
            if (quickBrandObj)
            {
                setBrand(quickBrandObj)
                setStep(2)
                setModels(quickBrandObj.mobiles ?? [])
            }
        }
    }, [response])
    
    const handleReset = () => {
        setStep(1)
        setError(null)
        setValidationErrors([]);
        setConditionError(null)
        setIssueError(null)
        setAccessoryError(null)
        
        setName('');
        setMobileNumber('');
        setCity('');
        setAddress('');

        setBrand(null)
        setModel(null)
        setVariant(null)
        setProvider('')
        setCondition1('')
        setCondition2('')
        setCondition3('')
        setCondition4('')
        setCondition5('')
        setCondition6('')
        setIssues(null)
        setHasBox(null)
        setHasCharger(null)

        mainViewRef.current.scrollIntoView();
    }

    const handleBackStep = () => {
        if (step == 2)
        {
            setStep(1)
            setModel(null)
        }
        if (step == 3)
        {
            setStep(2)
            setVariant(null)
        }
        if (step == 4)
        {
            if (model.has_variants)
            {
                setStep(3)
            }
            else
            {
                setStep(2)
            }
            setProvider('')
        }
        if (step == 5)
        {
            setStep(4)
            setCondition1('')
            setCondition2('')
            setCondition3('')
            setCondition4('')
            setCondition5('')
            setCondition6('')
            setConditionError(null)
        }
        if (step == 6)
        {
            setStep(5)
            setIssues(null)
            setIssueError(null)
        }
        if (step == 7)
        {
            setStep(6)
            setHasBox(null)
            setHasCharger(null)
            setAccessoryError(null)
        }
        if (step == 8)
        {
            setStep(9)
        }

        mainViewRef.current.scrollIntoView();
    }

    const handleBrand = (item) => {
        setBrand(item)
        setStep(2)
        setModels(item.mobiles ?? [])

        mainViewRef.current.scrollIntoView();
    }

    const handleModel = (item) => {
        setModel(item)
        if (item.has_variants)
        {
            setVariants(item.variants)
            setStep(3)
        }
        else
        {
            setStep(4)
        }

        mainViewRef.current.scrollIntoView();
    }

    const handleVariant = (item) => {
        setVariant(item)
        setStep(4)

        mainViewRef.current.scrollIntoView();
    }

    const handleProvider = (item) => {
        setProvider(item)
        setStep(5)

        mainViewRef.current.scrollIntoView();
    }

    const handleCondition = () => {
        setConditionError(null)
        if (!condition1) { setConditionError('Please select phone condition'); return }
        if (!condition2) { setConditionError('Please select screen scratches condition'); return }
        if (!condition3) { setConditionError('Please select screen condition'); return }
        if (!condition4) { setConditionError('Please select battery status'); return }
        if (!condition5) { setConditionError('Please select body damage'); return }
        if (!condition6) { setConditionError('Please select phone back condition'); return }

        setStep(6)

        mainViewRef.current.scrollIntoView();
    }

    const handleIssueSubmit = () => {
        setIssueError(null)
        if (issues === null) { setIssueError('Please select an option'); return }
        
        setStep(7);
        
        mainViewRef.current.scrollIntoView();
    }

    const handleIssue = (item) => {
        setIssues(issues => {
            if (issues === null) return [item]
            if (issues.includes(item)) return issues.filter(issue => issue != item)
            else return [...issues, item]
        })
    }

    const handleAccessory = () => {
        setAccessoryError(null)
        if (hasCharger === null) { setAccessoryError('Please choose an option for charger'); return }
        if (hasBox === null) { setAccessoryError('Please choose an option for box'); return }

        setStep(8)

        mainViewRef.current.scrollIntoView();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);

        let formData = new FormData();
        formData.append('name', name);
        formData.append('mobile_number', mobileNumber);
        formData.append('city', city);
        formData.append('address', address);
        if (variant) formData.append('variant', variant?.id);
        formData.append('provider', provider);
        formData.append('q1', condition1);
        formData.append('q2', condition2);
        formData.append('q3', condition3);
        formData.append('q4', condition4);
        formData.append('q5', condition5);
        formData.append('q6', condition6);
        if (issues !== null)
        {
            issues.map(issue => {
                formData.append('h[]', issue);
            })
        }
        if (hasCharger) formData.append('charger', 'yes');
        if (hasBox) formData.append('box', 'yes');
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash/'+(model?.id ?? 27), {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            mainViewRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setStep(9)
                
                setInstantCash(data.data)
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            mainViewRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }
    
    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div ref={mainViewRef} className="container">
                    <div data-aos="fade-right" data-aos-duration="1500">
                        {step != 9 ? (
                            <div className="instant-cash-progress">
                                Step {step} of 8
                                <span className={`progress progress-${step}`}>
                                    <span className={`step step-1 ${step>=1 ? 'active' : ''}`}/>
                                    <span className={`step step-2 ${step>=2 ? 'active' : ''}`}/>
                                    <span className={`step step-3 ${step>=3 ? 'active' : ''}`}/>
                                    <span className={`step step-4 ${step>=4 ? 'active' : ''}`}/>
                                    <span className={`step step-5 ${step>=5 ? 'active' : ''}`}/>
                                    <span className={`step step-6 ${step>=6 ? 'active' : ''}`}/>
                                    <span className={`step step-7 ${step>=7 ? 'active' : ''}`}/>
                                    <span className={`step step-8 ${step>=8 ? 'active' : ''}`}/>
                                </span>
                            </div>
                        ) : <div className="instant-cash-progress"></div>}
                        
                        {step != 1 && step != 9 ? <span onClick={handleBackStep} className='instant-cash-btn-go-back'>Go Back</span> : null}
                        {step == 1 ? <p className='instant-cash-phone-title'>Sell your old phone for cash</p> : null}
                        {step != 1 && step != 9 ? <p className='instant-cash-phone-title'>Sell your {step > 2 ? model?.name : brand?.name} for cash</p> : null}
                        
                        { fetchError && <div className="alert-msg-danger"><p className="mb-0">{ fetchError }</p></div> }
                        { error && step != 8 && <div className="alert-msg-danger"><p className="mb-0">{ error }</p></div> }
                        
                        <div className={`${step == 1 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Brand</h2>
                                <p>Choose the brand of your mobile phone.</p>
                            </div>
                            {isLoadingBrand ? (
                                <p className='loading-msg'>Loading data...</p>
                            ) : (
                                brands?.length > 0 ? (
                                    <div className='d-flex flex-wrap justify-content-center'>
                                        {brands.map(item => (
                                            <div key={item.id} onClick={() => handleBrand(item)} className='brand-box'>
                                                <img src={item.image_svg} />
                                                <p>{item.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null
                            )}
                        </div>

                        <div className={`${step == 2 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Model</h2>
                                <p>Choose your mobile phone.</p>
                            </div>
                            <div className='row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-10px limit-width'>
                                {models.map(item => (
                                    <div key={item.id} className='col'>
                                        <div onClick={() => handleModel(item)} className='model-box'>
                                            <img src={item.thumb_image} />
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={`${step == 3 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Storage</h2>
                                <p>Choose your phone storage.</p>
                            </div>
                            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center row-9px'>
                                {variants.map(item => (
                                    <div key={item.id} className='col'>
                                        <div onClick={() => handleVariant(item)} className={`variant-box ${variant?.id == item.id ? 'active' : ''}`}>
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* <div className={`${step == 4 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Estimated Cost</h2>
                                {brand?.name && model?.name ? (
                                    <p className='estimated-cost'>The estimated cost of your <br/><span>{brand.name} {model.name}{variant ? ' ('+variant.name+')' : ''}</span> is <span>{variant?.price_formatted ?? model?.price_formatted}</span></p>
                                ) : null}
                                <button onClick={() => {setStep(5);mainViewRef.current.scrollIntoView();}} className="yellow-button">Continue to sell</button>
                            </div>
                        </div> */}

                        <div className={`${step == 4 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Provider</h2>
                                <p>Choose your provider.</p>
                            </div>

                            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center row-9px'>
                                <div className='col'><div onClick={() => handleProvider('AT&T')} className={`variant-box ${provider == 'AT&T' ? 'active' : ''}`}><p>AT&T</p></div></div>
                                <div className='col'><div onClick={() => handleProvider('Factory Unlocked')} className={`variant-box ${provider == 'Factory Unlocked' ? 'active' : ''}`}><p>Factory Unlocked</p></div></div>
                                <div className='col'><div onClick={() => handleProvider('Sprint')} className={`variant-box ${provider == 'Sprint' ? 'active' : ''}`}><p>Sprint</p></div></div>
                                <div className='col'><div onClick={() => handleProvider('T-Mobile')} className={`variant-box ${provider == 'T-Mobile' ? 'active' : ''}`}><p>T-Mobile</p></div></div>
                                <div className='col'><div onClick={() => handleProvider('Verizon')} className={`variant-box ${provider == 'Verizon' ? 'active' : ''}`}><p>Verizon</p></div></div>
                            </div>
                        </div>

                        <div className={`${step == 5 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Condition</h2>
                                <p>Tell us about your phone condition.</p>
                            </div>

                            <p className='mb-1 condition-heading'>Phone condition</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setCondition1('Good')} className={`variant-box ${condition1 == 'Good' ? 'active' : ''}`}><p>Good</p></div></div>
                                <div className='col'><div onClick={() => setCondition1('Normal')} className={`variant-box ${condition1 == 'Normal' ? 'active' : ''}`}><p>Normal</p></div></div>
                                <div className='col'><div onClick={() => setCondition1('Bad')} className={`variant-box ${condition1 == 'Bad' ? 'active' : ''}`}><p>Bad</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Screen scratches condition</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setCondition2('Scratchless')} className={`variant-box ${condition2 == 'Scratchless' ? 'active' : ''}`}><p>Scratchless</p></div></div>
                                <div className='col'><div onClick={() => setCondition2('Minor')} className={`variant-box ${condition2 == 'Minor' ? 'active' : ''}`}><p>Minor</p></div></div>
                                <div className='col'><div onClick={() => setCondition2('Major')} className={`variant-box ${condition2 == 'Major' ? 'active' : ''}`}><p>Major</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Screen condition</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setCondition3('Fine')} className={`variant-box ${condition3 == 'Fine' ? 'active' : ''}`}><p>Fine</p></div></div>
                                <div className='col'><div onClick={() => setCondition3('Broken')} className={`variant-box ${condition3 == 'Broken' ? 'active' : ''}`}><p>Broken</p></div></div>
                                <div className='col'><div onClick={() => setCondition3('Not Working')} className={`variant-box ${condition3 == 'Not Working' ? 'active' : ''}`}><p>Not Working</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Battery status</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setCondition4('Good')} className={`variant-box ${condition4 == 'Good' ? 'active' : ''}`}><p>Good</p></div></div>
                                <div className='col'><div onClick={() => setCondition4('Normal')} className={`variant-box ${condition4 == 'Normal' ? 'active' : ''}`}><p>Normal</p></div></div>
                                <div className='col'><div onClick={() => setCondition4('Bad')} className={`variant-box ${condition4 == 'Bad' ? 'active' : ''}`}><p>Bad</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Body damage</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setCondition5('No Dents')} className={`variant-box ${condition5 == 'No Dents' ? 'active' : ''}`}><p>No Dents</p></div></div>
                                <div className='col'><div onClick={() => setCondition5('Minor')} className={`variant-box ${condition5 == 'Minor' ? 'active' : ''}`}><p>Minor</p></div></div>
                                <div className='col'><div onClick={() => setCondition5('Major')} className={`variant-box ${condition5 == 'Major' ? 'active' : ''}`}><p>Major</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Phone back condition</p>
                            <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-4 justify-content-center row-9px mb-2'>
                                <div className='col'><div onClick={() => setCondition6('Normal')} className={`variant-box ${condition6 == 'Normal' ? 'active' : ''}`}><p>Normal</p></div></div>
                                <div className='col'><div onClick={() => setCondition6('Cracked')} className={`variant-box ${condition6 == 'Cracked' ? 'active' : ''}`}><p>Cracked</p></div></div>
                                <div className='col'><div onClick={() => setCondition6('Minor Scratches')} className={`variant-box ${condition6 == 'Minor Scratches' ? 'active' : ''}`}><p>Minor Scratches</p></div></div>
                                <div className='col'><div onClick={() => setCondition6('Major Scratches')} className={`variant-box ${condition6 == 'Major Scratches' ? 'active' : ''}`}><p>Major Scratches</p></div></div>
                            </div>
                            
                            { conditionError && <div><div className="alert-msg-danger"><p className="mb-0">{ conditionError }</p></div></div> }
                            <button onClick={handleCondition} className="yellow-button">Go to next step</button>
                        </div>

                        <div className={`${step == 6 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Issues</h2>
                                <p>If your phone has issues, select them.</p>
                            </div>

                            <div className='row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-9px justify-content-center mb-2'>
                                <div className='col'><div onClick={() => {
                                    setIssues([])
                                }} className={`issue-box ${issues?.length == 0 ? 'active-green' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/none.svg' /></div>
                                    <p>No Issue</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Microphone')} className={`issue-box ${issues?.includes('Microphone') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/mic.svg' /></div>
                                    <p>Microphone</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Speaker')} className={`issue-box ${issues?.includes('Speaker') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/speaker.svg' /></div>
                                    <p>Speaker</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Camera')} className={`issue-box ${issues?.includes('Camera') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/camera.svg' /></div>
                                    <p>Camera</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Audio Receiver')} className={`issue-box ${issues?.includes('Audio Receiver') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/audio.svg' /></div>
                                    <p>Audio Receiver</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Bluetooth')} className={`issue-box ${issues?.includes('Bluetooth') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/bluetooth.svg' /></div>
                                    <p>Bluetooth</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Charging Port')} className={`issue-box ${issues?.includes('Charging Port') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/chargingport.svg' /></div>
                                    <p>Charging Port</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Wifi Signal')} className={`issue-box ${issues?.includes('Wifi Signal') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/wifi.svg' /></div>
                                    <p>Wifi Signal</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Face ID')} className={`issue-box ${issues?.includes('Face ID') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/faceid.svg' /></div>
                                    <p>Face ID</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Fingerprint')} className={`issue-box ${issues?.includes('Fingerprint') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/fingerprint.svg' /></div>
                                    <p>Fingerprint</p>
                                </div></div>
                                <div className='col'><div onClick={() => handleIssue('Heat Up')} className={`issue-box ${issues?.includes('Heat Up') ? 'active' : ''}`}>
                                    <div class="img-container"><img src='/images/issues/heatup.svg' /></div>
                                    <p>Heat Up</p>
                                </div></div>
                            </div>
                            
                            { issueError && <div><div className="alert-msg-danger"><p className="mb-0">{ issueError }</p></div></div> }
                            <button onClick={handleIssueSubmit} className="yellow-button">Go to next step</button>
                        </div>

                        <div className={`${step == 7 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Box & Charger</h2>
                                <p>Select if you have original IMEI box & original charger.</p>
                            </div>

                            <p className='mb-1 condition-heading'>Do you have original charger</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setHasCharger(true)} className={`variant-box ${hasCharger === true ? 'active' : ''}`}><p>Yes</p></div></div>
                                <div className='col'><div onClick={() => setHasCharger(false)} className={`variant-box ${hasCharger === false ? 'active' : ''}`}><p>No</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Do you have original IMEI box</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-2'>
                                <div className='col'><div onClick={() => setHasBox(true)} className={`variant-box ${hasBox === true ? 'active' : ''}`}><p>Yes</p></div></div>
                                <div className='col'><div onClick={() => setHasBox(false)} className={`variant-box ${hasBox === false ? 'active' : ''}`}><p>No</p></div></div>
                            </div>
                            
                            { accessoryError && <div><div className="alert-msg-danger"><p className="mb-0">{ accessoryError }</p></div></div> }
                            <button onClick={handleAccessory} className="yellow-button">Go to last step</button>
                        </div>

                        <div className={`${step == 8 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Contact Information</h2>
                                <p>Provide your contact information so we can give you exact value of your phone.</p>
                            </div>

                            <div className="banner_text">
                                {brand?.name && model?.name ? (
                                    <p className='estimated-cost'>The estimated cost of your <span>{brand.name} {model.name}{variant ? ' ('+variant.name+')' : ''}</span> is <span>{variant?.price_formatted ?? model?.price_formatted}</span></p>
                                ) : null}
                            </div>

                            { error && step == 8 && <div className="alert-msg-danger"><p className="mb-0">{ error }</p></div> }
                            
                            { validationErrors.length > 0 &&
                                <div className="alert-msg-danger">
                                    <h3>There was some error in submitted information, please try again.</h3>
                                    <ul>
                                        { validationErrors.map((validationError, index) => (
                                            <li key={index}>{ validationError }</li>
                                        ))}
                                    </ul>
                                </div>
                            }

                            <form onSubmit={handleSubmit} className='text-left'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-9'>
                                        <div className='row row-cols-1 row-cols-md-2 align-items-start row-9px mb-2'>
                                            <div className='col'>
                                                <p className='form-label'>Your full name</p>
                                                <input
                                                    value={name}
                                                    onChange={ (e) => setName(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your full name'
                                                    required />
                                            </div>

                                            <div className='col'>
                                                <p className='form-label'>Your mobile number</p>
                                                <input
                                                    value={mobileNumber}
                                                    onChange={ (e) => setMobileNumber(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your mobile number'
                                                    required />
                                            </div>

                                            <div className='col'>
                                                <p className='form-label'>Your city</p>
                                                <input
                                                    value={city}
                                                    onChange={ (e) => setCity(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your city'
                                                    required />
                                            </div>

                                            <div className='col'>
                                                <p className='form-label'>Your complete address</p>
                                                <textarea
                                                    value={address}
                                                    onChange={ (e) => setAddress(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your address'
                                                    rows={3} 
                                                    required ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 text-center'>
                                        <button type='submit' className="yellow-button">Sell your phone</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={`${step == 9 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Request Received</h2>
                                <p>Thank you for submitting your phone and contact details. We will contact you at the provided mobile number to give you exact value of your phone.</p>
                            </div>

                            <div className='information-box'>
                                <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3'>
                                    <div className='col' style={{ flex: '0 0 100%', maxWidth: '100%' }}><p className='mb-2'>Below are the details you submitted.</p></div>
                                    <div className='col'><p className='information-label'>Request number: <span>{instantCash?.order_number}</span></p></div>
                                    <div className='col'><p className='information-label'>Request date: <span>{instantCash?.request_date}</span></p></div>
                                    <div className='col'><p className='information-label'>Model: <span>{instantCash?.title}</span></p></div>
                                    <div className='col'><p className='information-label'>Name: <span>{instantCash?.name}</span></p></div>
                                    <div className='col'><p className='information-label'>Mobile number: <span>{instantCash?.mobile_number}</span></p></div>
                                    <div className='col'><p className='information-label'>City: <span>{instantCash?.city}</span></p></div>
                                    <div className='col'><p className='information-label'>Address: <span>{instantCash?.address}</span></p></div>
                                    <div className='col'><p className='information-label'>Provider: <span>{instantCash?.provider}</span></p></div>
                                    <div className='col'><p className='information-label'>Phone condition: <span>{instantCash?.q1}</span></p></div>
                                    <div className='col'><p className='information-label'>Screen scratches condition: <span>{instantCash?.q2}</span></p></div>
                                    <div className='col'><p className='information-label'>Screen condition: <span>{instantCash?.q3}</span></p></div>
                                    <div className='col'><p className='information-label'>Battery status: <span>{instantCash?.q4}</span></p></div>
                                    <div className='col'><p className='information-label'>Body damage: <span>{instantCash?.q5}</span></p></div>
                                    <div className='col'><p className='information-label'>Phone back condition: <span>{instantCash?.q6}</span></p></div>
                                    <div className='col'><p className='information-label'>Original charger available: <span>{instantCash?.charger}</span></p></div>
                                    <div className='col'><p className='information-label'>Original IMEI box available: <span>{instantCash?.box}</span></p></div>
                                    <div className='col'><p className='information-label'>{instantCash?.issues ? 'Issues with' : 'Issues'}: <span>{instantCash?.issues ? instantCash?.issues : 'None'}</span></p></div>
                                    <div className='col' style={{ flex: '0 0 100%', maxWidth: '100%' }}><button onClick={handleReset} className="yellow-button mb-0 mt-2">Sell another phone</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#f6f4fe" />
                    </svg>
                </div>
            </section>

            <div id="faqs" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2>Frequently Asked <span>Questions</span></h2>
                        <p>Need help getting started with ApnaPhone? Our FAQs have got you covered<br/> by providing clear and concise answers to all your questions.</p>
                    </div>

                    <div className="faq_panel">
                        <Accordion defaultActiveKey="0">
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header">
                                    <CustomAccordianToggle eventKey="0">In which states ApnaPhone available?</CustomAccordianToggle>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <div className="card-body">
                                        <p>ApnaPhone is available currently in California, Texas and Florida.</p>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header">
                                    <CustomAccordianToggle eventKey="1">How can I sell my phone?</CustomAccordianToggle>
                                </div>
                                <Accordion.Collapse eventKey="1">
                                    <div className="card-body">
                                        <p>You can submit Instant Cash request by entering details about mobile phones, our representative will get back to you as soon as possible.</p>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    );
}

function CustomAccordianToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <h2 className="mb-0" onClick={decoratedOnClick}>
            <button type="button" className={`btn btn-link ${isCurrentEventKey ? 'active' : ''}`} tabIndex="-1">
                {children}
                {isCurrentEventKey ? (
                    <img src="/images/icon-minus.svg" alt="View less" className="icon_faq minus"/>
                ) : (
                    <img src="/images/icon-plus.svg" alt="View more" className="icon_faq plus"/>
                )}
            </button>
        </h2>
    );
}
 
export default InstantCashBrandWhite;